import { createGlobalStyle } from 'styled-components'
import { Theme } from './theme'

export const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Inter';
  }

  body {
    margin: 0;
    background-color: ${Theme.colors.background};
    height: 100%;
  }

  .stop-scroll {
    height: 100%;
    overflow: hidden;
  }

  #root {
    display: flex;
  }

  html {
    font-size: ${Theme.typography.global.rem}px;
    font-family: ${Theme.typography.global.fontFamily};
    -webkit-font-smoothing: antialiased;
  }

  .chart-container{
    width: 100% !important;
  }

  .chart-root{
    width: 100% !important;
  }

  .chart-credits {
    display: none;
  }
  
  ${
    '' /* h1 {
    font-family: ${Theme.typography.h1.fontFamily};
    font-weight: ${Theme.typography.h1.fontWeight};
    font-size: ${Theme.typography.h1.fontSize}em;
    text-transform: ${Theme.typography.h1.case || "none"};
    letter-spacing: ${Theme.typography.h1.spacing}em;
  }
  h2 {
    font-family: ${Theme.typography.h2.fontFamily};
    font-weight: ${Theme.typography.h2.fontWeight};
    font-size: ${Theme.typography.h2.fontSize}em;
    text-transform: ${Theme.typography.h2.case || "none"};
    letter-spacing: ${Theme.typography.h2.spacing}em;
  }
  h3 {
    font-family: ${Theme.typography.h3.fontFamily};
    font-weight: ${Theme.typography.h3.fontWeight};
    font-size: ${Theme.typography.h3.fontSize}em;
    text-transform: ${Theme.typography.h3.case || "none"};
    letter-spacing: ${Theme.typography.h3.spacing}em;
  }
  h4 {
    font-family: ${Theme.typography.h4.fontFamily};
    font-weight: ${Theme.typography.h4.fontWeight};
    font-size: ${Theme.typography.h4.fontSize}em;
    text-transform: ${Theme.typography.h4.case || "none"};
    letter-spacing: ${Theme.typography.h4.spacing}em;
  }

  span.subtitle1 {
    font-family: ${Theme.typography.subtitle.fontFamily};
    font-weight: ${Theme.typography.subtitle.fontWeight};
    font-size: ${Theme.typography.subtitle.fontSize}em;
    text-transform: ${Theme.typography.subtitle.case || "none"};
    letter-spacing: ${Theme.typography.subtitle.spacing}em;
  }

  p {
    font-family: ${Theme.typography.body.fontFamily};
    font-weight: ${Theme.typography.body.fontWeight};
    font-size: ${Theme.typography.body.fontSize}em;
    text-transform: ${Theme.typography.body.case || "none"};
    letter-spacing: ${Theme.typography.body.spacing}em;
  }
  button {
    font-family: ${Theme.typography.button.fontFamily};
    font-weight: ${Theme.typography.button.fontWeight};
    font-size: ${Theme.typography.button.fontSize}em;
    text-transform: ${Theme.typography.button.case || "none"};
    letter-spacing: ${Theme.typography.button.spacing}em;
  } */
  }


  ${
    '' /* input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  } */
  }
  
  /* clears the ‘X’ from Internet Explorer */
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  /* clears the ‘X’ from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }
`
