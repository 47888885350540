/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { ThemeProvider } from "styled-components"
import { Theme } from "./src/styles/theme"
import { GlobalStyles } from "./src/styles/global"
import './src/fonts/typography.css'

// import "./src/fonts/typography.css"
 
 
 // You can delete this file if you're not using it
 
 export const wrapPageElement = ({element}) => {
   return  <>
             <GlobalStyles/>
             <ThemeProvider theme={Theme}>
               {element}
             </ThemeProvider>
           </>
 }

 const productionOnly = true

 export function onRouteUpdate({ location }) {
  
  if (
      (productionOnly && process.env.NODE_ENV !== 'production') ||
      !Array.isArray(window._hsq)
  ) {
      return;
  }

  // wrap inside a timeout to make sure react-helmet is done with it's changes (https://github.com/gatsbyjs/gatsby/issues/9139)
  // react-helmet is using requestAnimationFrame (https://github.com/nfl/react-helmet/blob/5.2.0/src/HelmetUtils.js#L296-L299)
  const trackPageView = () => {
      const path = location
          ? location.pathname + location.search + location.hash
          : undefined;
      window._hsq.push(['setPath', path]);
      window._hsq.push(['trackPageView']);
  };

  if (typeof window.requestAnimationFrame === 'function') {
      window.requestAnimationFrame(() => {
          window.requestAnimationFrame(trackPageView);
      });
  } else {
      // simulate 2 requestAnimationFrame calls
      setTimeout(trackPageView, 32);
  }
}

export const onInitialClientRender = () => {
    // wait to init GTM until after React has hydrated in this lifecycle
    const dataLayer = window.dataLayer || []
    dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
}

// function initGTMOnEvent(event) {
//   initGTM();
//   event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
// }

// function initGTM() {
// if (window.gtmDidInit) {
//   return false;
// }

// window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

// var script = document.createElement('script');

// script.type = 'text/javascript';
// script.async = true;
// script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GA_TRACKING_ID}`;

// script.onload = function() {
//   // eslint-disable-next-line no-undef
//   dataLayer.push({
//     // ensure PageViews are always tracked
//     event: 'gtm.js',
//     'gtm.start': new Date().getTime(),
//     'gtm.uniqueEventId': 0,
//     });
//   };

//   document.head.appendChild(script);
// }
  
// export function onClientEntry() {
//     document.onreadystatechange = function() {
//     if (document.readyState !== 'loading') setTimeout(initGTM, 1000);
//     };

//     document.addEventListener('scroll', initGTMOnEvent);
//     document.addEventListener('mousemove', initGTMOnEvent);
//     document.addEventListener('touchstart', initGTMOnEvent);
// }
