const Theme = {
  breakpoints: [
    ['phone_small', 320],
    ['phone', 376],
    ['phablet', 540],
    ['tablet', 768],
    ['desktop_small', 960],
    ['desktop', 1140],
    ['desktop_medium', 1280],
    ['desktop_large', 1440]
  ],
  colors: {
    primary: 'test',
  },
  palette: {
    primary: {
      100: '#E8FDE2',
      200: '#75E1B2',
      300: '#34AD78',
      400: '#00805D',
      500: '#014C33',
      600: '#002E1F', 
      700: '#000F0A'
    },
    text: {
      100: '#FFFFFF',
      200: '#485066',
      300: '#2A2F3C',
      400: '#D7DCEA',
      500: '#00805D',
    },
    background: {
      100: '#FFFFFF',
      200: '#F6F8FE',
      300: '#2A2F3C'
    }
  },
  default: {
    borderRadius: '1rem',
    container: 1280
  },
  typography: {
    global: {
      fontFamily: '"Inter", "Helvetica Neue"',
      rem: 16
    },
    h1: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 700,
      fontSize: 3,
      case: null,
      spacing: -0.015
    },
    h2: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 700,
      fontSize: 2.5,
      case: null,
      spacing: -0.005
    },
    h3: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 700,
      fontSize: 1.75,
      case: null,
      spacing: 0
    },
    h4: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 700,
      fontSize: 1.25,
      case: null,
      spacing: 0
    },
    h5: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 700,
      fontSize: 1,
      case: null,
      spacing: 0
    },
    subtitle: {
      fontFamily: '"Inter"',
      fontWeight: 500,
      fontSize: 1.25,
      case: null,
      spacing: 0.015
    },
    body: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 500,
      fontSize: 1,
      case: null,
      spacing: 0
    },
    button: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 700,
      fontSize: 1,
      case: null,
      spacing: 0
    },
    code: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 500,
      fontSize: 1,
      case: null,
      spacing: 0
    },
    input: {
      fontFamily: '"Inter", "Helvetica Neue"',
      fontWeight: 500,
      fontSize: 1,
      case: null,
      spacing: 0
    }
  }
}

Theme.colors = {
  primary: Theme.palette.primary['400'],
  background: Theme.palette.background['100'],
  surface: Theme.palette.background['200'],
  block: Theme.palette.primary['100'],
  onPrimary: Theme.palette.text['100'],
  onBackground: Theme.palette.text['300'],
  onSurface: Theme.palette.text['200'],
  onBlock: Theme.palette.primary['400'],
  error: '#e14d57'
}


const Defaults = {
  borderRadius: ({ theme }) => theme.default.borderRadius,
}

export { Theme, Defaults }

// let primary = new Palette([ "hsla(107,87%,94%,1)",
//                             "hsla(154,64%,67%,1)",
//                             "hsla(154,54%,44%,1)",
//                             "hsla(164,100%,25%,1)",
//                             "hsla(160,99%,15%,1)",
//                             "hsla(160,100%,9%,1)",
//                             "hsla(160,100%,3%,1)"
//                           ])

// class Palette {

//   constructor(colors) {
//     if(!(colors instanceof Array)) console.error("Palette argument colors not an array. e.g ['#fff','#000']");
//     this._colors = this._createColors(colors);
//   }

//   _createColors(colors) {
//     colors.map((v) => {
//       return new Color(v);
//     })
//   }

// }

// class Color {
//   constructor(color) {
//     this._h = 0;
//     this._s = 0;
//     this._l = 0;
//     this._a = 1;
//     this._createHslColor(color);
//   }

//   _createHslColor(color) {
//     if(!color.includes("hsla")) {
//       throw new Error("Color must be HSLA, recieved: " + color)
//     }

//     let sep = color.indexOf(",") > -1 ? "," : " ";
//     color = color.substr(5).split(")")[0].split(sep);

//     if (color.indexOf("/") > -1)
//       color.splice(3,1);

//     let h = color[0],
//         s = color[1].substr(0,color[1].length - 1) / 100,
//         l = color[2].substr(0,color[2].length - 1) / 100,
//         a = color[3];

//     if (h.indexOf("deg") > -1)
//       h = h.substr(0,h.length - 3);
//     else if (h.indexOf("rad") > -1)
//       h = Math.round(h.substr(0,h.length - 3) * (180 / Math.PI));
//     else if (h.indexOf("turn") > -1)
//       h = Math.round(h.substr(0,h.length - 4) * 360);
//     if (h >= 360)
//       h %= 360;

//     this._h = parseInt(h);
//     this._s = s;
//     this._l = l;
//     this._a = parseInt(a);

//   }

//   toString() {
//     return `hsla(${this._h},${this._s},${this._l},${this._a})`
//   }

//   hsla(data) {
//     return this.hsl({alpha: true, ...data})
//   }

//   hsl({alpha = false, h = 0, s = 0, l = 0, a = 0} = {alpha: false, h: 0, s: 0, l: 0, a: 0}) {
//     h += this._h;
//     s += this._s * 100;
//     l += this._l * 100;
//     a += this._a;
//     if(alpha) {
//       return `hsla(${h},${s}%,${l}%,${a})`
//     } else {
//       return `hsl(${h},${s}%,${l}%)`
//     }
//   }
// }

// console.log(new Color("hsla(164,100%,25%,1)").hsl({h: -20}));
