exports.components = {
  "component---src-page-templates-all-metrics-js": () => import("./../../../src/page-templates/all-metrics.js" /* webpackChunkName: "component---src-page-templates-all-metrics-js" */),
  "component---src-page-templates-all-roles-js": () => import("./../../../src/page-templates/all-roles.js" /* webpackChunkName: "component---src-page-templates-all-roles-js" */),
  "component---src-page-templates-all-services-js": () => import("./../../../src/page-templates/all-services.js" /* webpackChunkName: "component---src-page-templates-all-services-js" */),
  "component---src-page-templates-contributor-js": () => import("./../../../src/page-templates/contributor.js" /* webpackChunkName: "component---src-page-templates-contributor-js" */),
  "component---src-page-templates-metric-comparison-js": () => import("./../../../src/page-templates/metric-comparison.js" /* webpackChunkName: "component---src-page-templates-metric-comparison-js" */),
  "component---src-page-templates-metrics-js": () => import("./../../../src/page-templates/metrics.js" /* webpackChunkName: "component---src-page-templates-metrics-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-metric-temp-js": () => import("./../../../src/pages/all-metric-temp.js" /* webpackChunkName: "component---src-pages-all-metric-temp-js" */),
  "component---src-pages-all-metrics-filter-js": () => import("./../../../src/pages/all-metrics-filter.js" /* webpackChunkName: "component---src-pages-all-metrics-filter-js" */),
  "component---src-pages-all-services-js": () => import("./../../../src/pages/all-services.js" /* webpackChunkName: "component---src-pages-all-services-js" */),
  "component---src-pages-chatbot-js": () => import("./../../../src/pages/chatbot.js" /* webpackChunkName: "component---src-pages-chatbot-js" */),
  "component---src-pages-contribute-js": () => import("./../../../src/pages/contribute.js" /* webpackChunkName: "component---src-pages-contribute-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */)
}

